(function ($, undefined) {

	$('.stock-gallery').on('click', '.stock-gallery-thumb', function (ev) {
		ev.preventDefault();

		var target   = $(ev.delegateTarget).find('.stock-gallery-image');
		var image    = target.find('img');
		var thumb    = $(this);
		var newImage = $(thumb.data('image'));

		if (newImage.prop("complete")) {
			target.attr('href', thumb.attr('href'));
			image.replaceWith(newImage);
		} else {
			target.addClass('is-loading');
			newImage.one({
				load: function () {
					image.replaceWith(this);
					target.attr('href', thumb.attr('href')).removeClass('is-loading');
				},

				error: function () {
					target.removeClass('is-loading');
				}
			});
		}

		$([document.documentElement, document.body]).animate({
			scrollTop: target.position().top - $('.top').height() - 20
		});
	})

	.on('mousemove mouseleave', '.stock-gallery-image', function (ev) {
		var self = $(this);
		var zoom = ev.type === 'mousemove';

		self.toggleClass('is-zoom', zoom);

		if (! zoom) {
			return;
		}

		var w = self.width();
		var h = self.height();
		var x = ev.offsetX;
		var y = ev.offsetY;

		if (x === undefined || y === undefined) {
			var offset = self.offset();
			x = ev.pageX - offset.left;
			y = ev.pageY - offset.top;
		}

		x = x / w * 100;
		y = y / h * 100;

		self.css({
			backgroundImage: 'url(' + this.href + ')',
			backgroundPosition: x + '% ' + y + '%'
		});
	})

	.on('click', '.stock-gallery-image', function (ev) {
		ev.preventDefault();

		var gallery = $(ev.delegateTarget);
		var links   = gallery.data('links');
		var link    = this.href;

		if (! links) {
			var items = gallery.find('.stock-gallery-thumb');
			if (! items.length) {
				items = gallery.find('.stock-gallery-image');
			}

			links = $('<div />').hide();
			items.each(function () {
				$('<a />').attr({
					'data-rel': 'lightcase:stock-gallery',
					'href': this.href
				}).appendTo(links);
			});

			links = links.appendTo(gallery).children();
			links.lightcase({
				maxWidth: 1200,
				maxHeight: 1200,
				shrinkFactor: 0.85
			});

			gallery.data('links', links);
		}

		links.filter(function () {
			return this.href === link;
		}).click();
	});

})(jQuery);