(function ($) {

	if (! $('.js-map').length) {
		return;
	}

	window.maps = function () {
		$('.js-map').each(function () {
			var self = $(this);
			var pos  = new google.maps.LatLng(parseFloat(self.attr('data-lat')), parseFloat(self.attr('data-lng')));
			var map  = new google.maps.Map(this, {
				zoom: parseInt(self.attr('data-zoom')) || 15,
				center: pos,
				scrollwheel: false
			});

			new google.maps.Marker({
				icon: self.attr('data-icon'),
				map: map,
				position: pos
			});
		});
	};

	$.getScript('https://maps.googleapis.com/maps/api/js?callback=maps');

})(jQuery);