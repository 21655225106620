(function ($) {

	$('img.size-cover').each(function () {
		if (! ('object-fit' in this.style)) {
			$(this).hide().wrap('<div />').parent().addClass(this.className).css({
				backgroundImage: 'url(%)'.replace('%', this.currentSrc || this.src)
			});
		}
	});

})(jQuery);