(function ($) {

	$(document.body).on('change', '.js-change-submit', function () {
		$(this).closest('form').submit();
	})

	.on('submit', 'form.js-submit', function (ev) {
		ev.preventDefault();

		var form = $(this);

		form.find('.js-loading').addClass('is-loading');
		form.find(':submit').prop('disabled', true);

		$.ajax({
			url: form.attr('action') || window.location.href,
			method: form.attr('method'),
			data: form.serializeArray(),
			success: renderAjax
		}).always(function () {
			form.find('.js-loading').removeClass('is-loading');
			form.find(':submit').prop('disabled', false);
		});
	})

	.on('click', 'a.page-numbers', function (ev) {
		ev.preventDefault();

		$(this).addClass('is-loading');

		$.ajax({
			url: this.href,
			method: "GET",
			success: renderAjax
		});
	});

	function renderAjax(response) {
		var result = $('<div />').append($.parseHTML(response));
		var title  = result.find('title').text();
		var url    = result.find('meta[name=REQUEST_URL]').attr('content');

		$('.main').replaceWith(result.find('.main'));

		result.remove();
		result = null;

		if (title) {
			document.title = title;
		}

		if (url && window.history.replaceState) {
			try {
				window.history.replaceState(null, null, url);
			} catch (e) {}
		}

		var target = $('.pagination').first();
		if (! target.length) {
			target = $('.main');
		}

		var top       = $('.top');
		var offset    = top.outerHeight() + (parseInt(top.css('top')) || 0);
		var scrollTop = target.offset().top - (parseInt(target.css('marginTop')) || 0) - offset;

		$([document.documentElement, document.body]).animate({
			scrollTop: scrollTop
		});
	}

})(jQuery);