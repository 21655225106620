(function ($) {

	$('.article_main a').filter(function () {
		return /\.(jpe?g|gif|png|webp|tiff)([\?#].*)?$/.test(this.href);
	})
	.attr('data-rel', 'lightcase:gallery')
	.lightcase({
		maxWidth: 1200,
		maxHeight: 1200,
		shrinkFactor: 0.95
	});

	$('a[data-modal]').lightcase({
		maxWidth: 600,
		maxHeight: 1024,
		shrinkFactor: 1,
		fullScreenModeForMobile: false,
		inline: {
			width: 'auto',
			height: 'auto'
		},
		onBeforeShow: {
			forms: function () {
				var inner = lightcase.get('contentInner');
				if (inner.has('.gform_wrapper')) {
					inner.children().addClass('is-form');
					$(document).off('gform_post_render.form-modal').on('gform_post_render.form-modal', function () {
						inner.children().animate({ scrollTop: 0 });
						lightcase.resize();
					});
				}
			}
		},
		onClose: {
			forms: function () {
				$(document).off('gform_post_render.form-modal');
			}
		}
	});

})(jQuery);