(function ($) {
	try {
		if (! localStorage.getItem('stock-search-hidden')) {
			throw new Error();
		}
	} catch (e) {
		var toggles = $('.js-input-toggle[name="stock-search[unit]"]');
		if (! toggles.filter(':checked').length) {
			toggles.first().prop('checked', true).trigger('change');
		}
	}

	$('.stock-search-hide').on('click', function () {
		$('.js-input-toggle[name="stock-search[unit]"]').prop('checked', false).trigger('change');
		try {
			localStorage.setItem('stock-search-hidden', '1');
		} catch (e) {}
	});
})(jQuery);